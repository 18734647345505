import "react-virtualized/styles.css";

import {
  AutoSizer,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  List,
} from "react-virtualized";
import {
  Button,
  FullModal,
  IBoard,
  IBoardStyle,
  InstallableContainer,
  NativeVisualEditorBridge,
  NotificationBanner,
  Spacer,
} from "@vestaboard/installables";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";
import { IMessage, IParams } from "../types";
import { useCallback, useEffect, useState } from "react";
import { useFormat, useParams } from "../hooks";

import { AddMessageMenu } from "../components/AddMessageMenu";
import { Favorites } from "./Favorites";
import { Fixed } from "../components";
import { ISubscriptionResult } from "../../../backend/types";
import { ImportFromCsv } from "./ImportFromCsv";
import { NoMessages } from "../components/NoMessages";
import ReactDOM from "react-dom";
import { RenderListMessage } from "../components/RenderListMessage";
import { Scrollable } from "../components/Scrollable";
import { Templates } from "./Templates";
import { useCardSize } from "../hooks/useCardSize";
import { useEditingMessage } from "../hooks/useEditingMessage";
import { useGetQuietHours } from "../hooks/useGetQuietHours";
import { useListColorTemplates } from "../hooks/useListColorTemplates";
import { useSendMessage } from "../hooks/useSendMessage";
import { useSetMessages } from "../hooks/useSetMessages";
import { v4 as uuid } from "uuid";

interface IMessages {
  data: ISubscriptionResult | null;
  setData: (data: ISubscriptionResult | null) => void;
  setTab: (tab: number) => void;
}

export const Messages = (props: IMessages) => {
  const params = useParams<IParams>();
  const [sendMessage] = useSendMessage();
  const [saveMessages, { data }] = useSetMessages();
  const [view, setView] = useState("messages");
  const { editing, setEditing } = useEditingMessage();
  const { data: quietHoursData } = useGetQuietHours({
    subscriptionConfigurationToken: params.subscriptionConfigurationToken,
  });
  const [messages, setMessagesArray] = useState<IMessage[]>(
    props?.data?.messages?.length ? (props.data.messages as IMessage[]) : []
  );
  const [toggled, setToggled] = useState(false);
  const { format } = useFormat();
  const { height: cardHeight, width: cardWidth } = useCardSize();
  const { setData } = props;

  // Warm the cache
  useListColorTemplates();

  const isSetupComplete =
    props?.data?.title && props?.data?.title !== "My Channel";

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data, setData]);

  const [visualEditorId, setVisualEditorId] = useState<string | null>(null);

  const setMessages = useCallback(
    async (messages) => {
      setMessagesArray(messages);
      await saveMessages({
        variables: {
          id: params.subscriptionId,
          messages,
        },
      });
    },
    [params, saveMessages]
  );
  const subscriptionId = params.subscriptionId;
  useEffect(() => {
    // poll for message
    if (
      visualEditorId
      // &&
      // only test mine for now
      // subscriptionId === "d225b5d0-66b7-4a1a-8a0c-c835869b62c2"
    ) {
      const interval = setInterval(() => {
        try {
          fetch(
            `https://platform.vestaboard.com/v3.0/visual-editor-session/${visualEditorId}`
          ).then(async (response) => {
            if (!response.ok) {
              // 404 on message usually, message not done being edited
              return;
            }
            const data = (await response.json()) as {
              characters: string;
            };
            if (!data.characters) {
              return;
            }
            if (!editing) {
              setMessages([
                ...messages,
                {
                  id: uuid(),
                  characters: JSON.parse(data.characters),
                },
              ]);
            } else {
              setMessages(
                messages.map((message) =>
                  message.id === editing
                    ? {
                        id: message.id,
                        characters: JSON.parse(data.characters),
                      }
                    : message
                )
              );
              setEditing(null);
            }
            clearInterval(interval);
            setVisualEditorId(null);
          });
        } catch (e) {}
      }, 1000);
    }
  }, [
    subscriptionId,
    setVisualEditorId,
    visualEditorId,
    setEditing,
    editing,
    setMessages,
    messages,
  ]);

  const rowRenderer =
    (messages: IMessage[], launch: (template?: string) => void) =>
    ({ index, style }) => {
      const message = messages[index];

      if (!message) {
        return <div key="drag-null" style={style} />;
      }

      return (
        <Draggable
          key={`drag-${message.id}`}
          draggableId={`draggable-${message.id}`}
          index={index}
          style={style}
        >
          {(provided) => (
            <RenderListMessage
              message={message}
              provided={provided}
              style={style}
              cardWidth={cardWidth}
              boardStyle={props?.data?.boardStyle as IBoardStyle}
              sendMessage={sendMessage}
              setMessages={setMessages}
              messages={messages}
              launch={launch}
              setEditing={setEditing}
              editing={editing}
              isNative={params.platform !== "web"}
            />
          )}
        </Draggable>
      );
    };

  if (view === "importCsv") {
    return (
      <ImportFromCsv
        boardStyle={props?.data?.boardStyle as IBoardStyle}
        onViewChange={setView}
        setMessages={(items) => {
          setMessages([
            ...messages,
            ...items.map((item) => ({
              id: uuid(),
              characters: item,
            })),
          ]);
        }}
      />
    );
  }

  return (
    <NativeVisualEditorBridge
      setVisualEditorId={setVisualEditorId}
      boardStyle={
        (props?.data?.boardStyle as IBoardStyle) || ("black" as "black")
      }
      onComplete={async (_id, text, characters) => {
        const messageCharacters = characters
          ? (JSON.parse(characters) as IBoard)
          : await format(text || "");

        if (!editing) {
          setMessages([
            ...messages,
            {
              id: uuid(),
              characters: messageCharacters,
            },
          ]);
        } else {
          setMessages(
            messages.map((message) =>
              message.id === editing
                ? {
                    id: message.id,
                    characters: messageCharacters,
                  }
                : message
            )
          );
          setEditing(null);
        }
      }}
      renderWebVisualEditor={(open, editor, setOpen) => {
        return open ? (
          <FullModal
            onClose={() => {
              if (editing) {
                setEditing(null);
              }
              if (setOpen) {
                setOpen(false);
              }
            }}
          >
            {editor}
          </FullModal>
        ) : (
          <div />
        );
      }}
    >
      {(launch) => (
        <>
          {quietHoursData?.isQuietHours ? (
            <InstallableContainer pad>
              <div
                style={{
                  paddingTop: 24,
                  textAlign: "center",
                }}
              >
                <NotificationBanner
                  visible={quietHoursData?.isQuietHours || true}
                  text={quietHoursData?.formattedQuietHours || ""}
                />
              </div>
            </InstallableContainer>
          ) : null}
          {!messages?.length ? (
            <Scrollable>
              <Spacer size="extraLarge" />
              <InstallableContainer pad>
                <NoMessages launch={launch} setView={setView} />
                <Spacer size="extraLarge" />
              </InstallableContainer>
            </Scrollable>
          ) : null}
          <DragDropContext
            onDragEnd={(result: DropResult, provided: ResponderProvided) => {
              if (!result.destination) {
                return;
              }

              const startIndex = result.source.index;
              const endIndex = result.destination.index;

              const newMessages = Array.from(messages);
              const [removed] = newMessages.splice(startIndex, 1);
              newMessages.splice(endIndex, 0, removed);

              setMessages(newMessages);
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <Droppable
                  droppableId="droppable"
                  mode="virtual"
                  renderClone={(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot,
                    rubric: DraggableRubric
                  ) => (
                    <RenderListMessage
                      message={messages[rubric.source.index]}
                      provided={provided}
                      style={provided.draggableProps.style}
                      cardWidth={cardWidth}
                      boardStyle={props?.data?.boardStyle as IBoardStyle}
                      sendMessage={sendMessage}
                      setMessages={setMessages}
                      messages={messages}
                      launch={launch}
                      setEditing={setEditing}
                      editing={editing}
                    />
                  )}
                >
                  {(provided) => {
                    return (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <List
                          height={height}
                          rowCount={messages.length + 1}
                          rowHeight={cardHeight}
                          width={width}
                          ref={(ref) => {
                            if (ref) {
                              const element = ReactDOM.findDOMNode(ref);
                              if (element instanceof HTMLElement) {
                                provided.innerRef(element);
                              }
                            }
                          }}
                          rowRenderer={rowRenderer(messages, launch)}
                        />
                      </div>
                    );
                  }}
                </Droppable>
              )}
            </AutoSizer>
          </DragDropContext>

          <Spacer size="extraLarge" />

          {messages.length ? (
            <Fixed
              height={isSetupComplete ? 105 : 165}
              orientation="bottom"
              disablePlaceholder
            >
              {!isSetupComplete ? (
                <InstallableContainer pad>
                  <div style={{ height: 30 }} />
                  <Button
                    buttonType="white"
                    onClick={() => {
                      props.setTab(1);
                    }}
                  >
                    Complete Setup
                  </Button>
                </InstallableContainer>
              ) : null}
              <InstallableContainer pad>
                <div style={{ height: isSetupComplete ? 30 : 12 }} />
                <Button
                  buttonType="white"
                  onClick={() => {
                    setToggled(true);
                  }}
                >
                  Add
                </Button>
              </InstallableContainer>
            </Fixed>
          ) : null}
          <AddMessageMenu
            toggled={toggled}
            setToggled={setToggled}
            launch={launch}
            setView={setView}
          />
          <Favorites
            boardStyle={props?.data?.boardStyle as IBoardStyle}
            visible={view === "importFavorites"}
            setVisible={() => {
              setView("messages");
            }}
            setMessages={(items) => {
              setMessages([
                ...messages,
                ...items.map((item) => ({
                  id: uuid(),
                  characters: item,
                })),
              ]);
            }}
          />
          <Templates
            boardStyle={props?.data?.boardStyle as IBoardStyle}
            visible={view === "importTemplates"}
            setVisible={() => {
              setView("messages");
            }}
            setMessages={(items) => {
              setMessages([
                ...messages,
                ...items.map((item) => ({
                  id: uuid(),
                  characters: item,
                })),
              ]);
            }}
          />
        </>
      )}
    </NativeVisualEditorBridge>
  );
};
